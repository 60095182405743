import { loop, Cmd } from 'redux-loop';
import {navigate} from 'gatsby';
import {assocPath} from 'ramda';
import {pipe} from 'fp-ts/pipeable';
import {API} from 'aws-amplify';

import * as mutations from '../../graphql/mutations';

//++++++++
// TYPES +
//++++++++

//+++++++++++++++++
// REDUCER HELPERS+
//+++++++++++++++++

const submitFormHelper = async ({input, html, files}) => {
  const mutation = mutations.createProjectForm;
  const variables = {
    input
  };
  try {
    // throw {msg: 'There was an error! Please try again or call us at (859) 255-0077.', e: 'Error'};
    const sendEmail = await fetch('https://55cqd9yks8.execute-api.us-east-2.amazonaws.com/default/projectFormEmailNotification-staging', {
      method: 'post',
      mode: 'no-cors',
    //   headers: {
    //      'Access-Control-Request-Origin':'*',
    //      'Access-Control-Request-Headers':'POST',
    //   },
      body: JSON.stringify({
        customForm: {
          to: process.env.NODE_ENV === 'development' ? 'johnwcothran@gmail.com,soccadelic@gmail.com' : 'johnwcothran@gmail.com,larry@asphaltanimals.com,receptionist@asphaltanimals.com',
          from: 'candrasphalt@asphaltanimals.com',
          subject: 'C&R Asphalt Free Estimate Form',
          files,
          html,
        }
      })
    });
    // return 'success!';
    return process.env.NODE_ENV === 'development' ? null : await API.graphql({
      query: mutation,
      variables,
      authMode: 'AWS_IAM'
    });
  } catch (e) {
    console.error(e);
    throw {msg: 'There was an error! Please try again or call us at (859) 255-0077.', e};
  }

};

//+++++++++
// ACTIONS+
//+++++++++

const UPDATE = 'actions/project/UPDATE';
const SUBMIT_FORM = 'actions/project/SUBMIT_FORM';
const SUBMIT_FORM_SUCCESS = 'actions/project/SUBMIT_FORM_SUCCESS';
const SUBMIT_FORM_FAIL = 'actions/project/SUBMIT_FORM_FAIL';

//++++++++++
// REDUCERS+
//++++++++++

export const questions = {
  projectType: {
    questionText: 'What type of job can we help you with?',
    answer: null,
    required: true,
    type: 'projectType',
    options: [
      { label: 'Commercial', value: 'Commercial' },
      { label: 'Residential', value: 'Residential' },
      { label: 'Farm', value: 'Farm' },
      { label: 'Other', value: null },
    ]
  },
  firstName: {
    questionText: 'What is your first name?',
    answer: null,
    required: true,
    type: 'firstName',
    label: 'First Name',
    inputPropType: 'text'
  },
  lastName: {
    questionText: 'What is your last name?',
    answer: null,
    required: true,
    type: 'lastName',
    label: 'Last Name',
    inputPropType: 'text'
  },
  organizationName: {
    questionText: "What is your company or organization's name?",
    answer: null,
    required: true,
    // skip: true,
    type: 'organizationName',
    label: 'Organization Name',
    inputPropType: 'text'
  },
  organizationAddress: {
    reportLabel: 'Business/Organization Address (SERVING KY & SURROUNDING STATES)',
    questionText: "What is the street address of your organzation?",
    answer: null,
    address: {
      streetAddress: null,
      city: null,
      state: null,
      zip: null
    },
    required: true,
    type: 'organizationAddress',
    label: '111 Example St,\nLexington, KY 40515',
    inputPropType: 'address',
    multiline: true
  },
  email: {
    questionText: "What is your email?",
    answer: null,
    required: true,
    type: 'email',
    label: 'Email',
    inputPropType: 'email'
  },
  phone: {
    questionText: "What is your phone?",
    answer: null,
    required: true,
    type: 'phone',
    label: 'Phone',
    inputPropType: 'tel'
  },
  jobAddress: {
    reportLabel: 'Job Address',
    questionText: "What is the street address of the job site?",
    answer: null,
    address: {
      streetAddress: null,
      city: null,
      state: null,
      zip: null
    },
    required: true,
    type: 'jobAddress',
    label: '111 Example St,\nLexington, KY 40515',
    inputPropType: 'address',
    multiline: true,
  },
  previousCustomer: {
    questionText: 'Have you worked with us before?',
    answer: null,
    required: true,
    type: 'previousCustomer',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  referralType: {
    questionText: 'How did you hear about us?',
    answer: null,
    required: true,
    type: 'referralType',
    options: [
      { label: 'Customer referral', value: 'Customer Referral' },
      { label: 'Yellow Pages', value: 'Yellow Pages' },
      { label: 'In The Mail', value: 'In The Mail' },
      { label: 'Search Engines', value: 'Search Engines' },
      { label: 'Social Media', value: 'Social Media' },
      { label: 'Facebook Ad', value: 'Facebook Ad' },
      { label: 'Instagram Ad', value: 'Instagram Ad' },
      { label: 'Other', value: null },
    ]
  },
  serviceType: {
    questionText: 'What type of services are you looking for?',
    answer: [],
    multipleSelect: true,
    required: true,
    type: 'serviceType',
    options: [
      { label: 'New Pave', value: 'New Pave' },
      { label: 'Asphalt Overlay', value: 'Asphalt Overlay' },
      { label: 'Patch', value: 'Patch' },
      { label: 'Crackfill', value: 'Crackfill' },
      { label: 'Sealcoat', value: 'Sealcoat' },
      { label: 'Striping', value: 'Striping' },
      { label: 'New Construction', value: 'New Construction' },
      { label: 'Millings', value: 'Millings' },
      { label: 'Expand Existing', value: 'Expand Existing' },
      { label: 'Remove/Replace', value: 'Remove/Replace' },
    ]
  },
  customerMessage: {
    questionText: "Please provide any comments or specific questions you may have.",
    answer: null,
    // required: true,
    type: 'customerMessage',
    label: 'Message to C & R',
    inputPropType: 'text',
    multiline: true
  },
}

export const initialState = {
    form: {
        currentQuestion: null,
        questions,
        review: false,
        submittingForm: false,
        submitFormError: null,
        open: false
    }
};

export default function projectStart (state = initialState, action) {
    switch (action.type) {
        case UPDATE: {
            const { keypath, value } = action;
            return assocPath(keypath, value, state);
        }
        case SUBMIT_FORM: {
          const newState = pipe(state,
            st => assocPath(['form', 'submittingForm'], true, st),
          );
          const input = {
              projectType: state.form.questions.projectType.answer?.value,
              email: state.form.questions.email.answer?.value,
              phone: state.form.questions.phone.answer?.value,
              firstName: state.form.questions.firstName.answer?.value,
              lastName: state.form.questions.lastName.answer?.value,
              organizationName: state.form.questions.organizationName.answer?.value,
              organizationAddress: state.form.questions.organizationAddress.answer?.value,
              jobAddress: state.form.questions.jobAddress.answer?.value,
              referralType: state.form.questions.referralType.answer?.value,
              serviceType: state.form.questions.serviceType.answer?.map(a => a.value),
              customerMessage: state.form.questions.customerMessage.answer?.value,
              previousCustomer: state.form.questions.previousCustomer.answer?.value === 'Yes' ? true : false,
          };
          const html = state.form.report;
          const files = [];
          return loop(
            newState,
            Cmd.run(submitFormHelper, {
              args: [{input, html, files}],
              successActionCreator: onSubmitFormSuccess,
              failActionCreator: onSubmitFormFail
            })
          )
        }
        case SUBMIT_FORM_SUCCESS: {
          const newState = pipe(state,
            st => assocPath(['form', 'submittingForm'], false, st),
            st => assocPath(['form'], initialState.form, st),
          );
          navigate('/thank-you-free-estimate/?success=true');
          return newState;
        }
        case SUBMIT_FORM_FAIL: {
          const { result } = action;
          const newState = pipe(state,
            st => assocPath(['form', 'submittingForm'], false, st),
            st => assocPath(['form', 'submitFormError'], result, st),
          );
          return newState;
        }
        default:
            return state;
    }
}

//++++++++++++++++++
// ACTIONS CREATORS+
//++++++++++++++++++


export function update(keypath, value) {
    return {
        type: UPDATE,
        keypath,
        value
    };
}

export function submitForm () {
  return {
    type: SUBMIT_FORM
  }
}

function onSubmitFormSuccess () {
  return {
    type: SUBMIT_FORM_SUCCESS
  }
}
function onSubmitFormFail (result) {
  return {
    type: SUBMIT_FORM_FAIL,
    result
  }
}
